const INCREMENT_COUNTER = 'INCREMENT_COUNTER'

const state = {
  counter: 0
}

const actions = {
  increment ({ commit }, val) {
    commit(INCREMENT_COUNTER, val)
  },
}

const mutations = {
  [INCREMENT_COUNTER] (state, val) {
    state.counter += val
  },
}

export default {
  state,
  actions,
  mutations
}